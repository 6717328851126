/* eslint-disable */
import classNames from 'classnames';
import React, { useState, useRef } from 'react';

import IconArrow from '@svg-icons/IconArrow';
import IconPlus from '@svg-icons/IconPlus';

import styles from './Collapse.css';

type Props = {
  title: JSX.Element | string;
  children: JSX.Element;
  noBorder?: boolean;
  bold?: boolean;
  iconType?: 'plus' | 'arrow';
};

const Collapse = ({
  title,
  children,
  bold,
  noBorder = false,
  iconType = 'plus',
}: Props) => {
  const [isOpen, setOpen] = useState(false);
  const collapseContent = useRef(null);
  const maxHeight = isOpen ? collapseContent.current.scrollHeight : 0;
  const collapseIcon =
    iconType === 'plus' ? (
      <IconPlus className={classNames({ close: isOpen })} />
    ) : (
      <IconArrow direction={isOpen ? 'up' : 'down'} />
    );

  return (
    <>
      <div
        className={classNames('collapse-menu', {
          'no-border': noBorder,
          bold: !!bold,
        })}
      >
        <button
          type="button"
          className="collapse-heading"
          onClick={() => setOpen(!isOpen)}
        >
          <p className="collapse-title">{title}</p>
          {collapseIcon}
        </button>

        <div
          className={classNames('collapse-content', {
            'is-open': isOpen,
          })}
          ref={collapseContent}
          style={{ maxHeight }}
        >
          {children}
        </div>
      </div>

      <style jsx>{styles}</style>
    </>
  );
};

export default Collapse;
