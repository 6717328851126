/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const { mobile } = BREAKPOINT;

const styles = css`
  .footer-menu-container {
    flex: 4;
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    flex-flow: row wrap;

    @media screen and (max-width: ${mobile}px) {
      flex-direction: column;
    }

    .footer-menu-category-title,
    .footer-menu-category-external-link,
    .footer-menu-item {
      padding: 0.5rem 0;
    }

    .footer-menu-category {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      font-style: normal;
      font-size: 14px;
      padding: 24px 0;

      @media screen and (max-width: ${mobile}px) {
        width: 100%;
        padding: 0;
      }

      .footer-menu-category-title {
        line-height: 24px;
        align-items: center;
        font-weight: bold;
        color: ${COLORS.black900};

        @media screen and (max-width: ${mobile}px) {
          display: none;
          visibility: hidden;
        }
      }

      .footer-menu-item {
        :global(a) {
          font-weight: normal;
          color: ${COLORS.black900};
        }
      }
    }

    .footer-menu-category-external-link {
      @media screen and (max-width: ${mobile}px) {
        width: 100%;
        border-top: 1px solid ${COLORS.black10};
        margin-top: 0.5rem;
        padding: 1rem 0;

        & + .footer-menu-category-external-link {
          border-top: none;
          padding-top: 0;
        }
      }

      :global(a) {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 24px;
        font-weight: bold;
        font-style: normal;
        font-size: 14px;
        color: ${COLORS.black900};

        @media screen and (max-width: ${mobile}px) {
          font-size: 1rem;
        }

        :global(svg) {
          margin-left: 1rem;
        }
      }
    }
  }
`;

export default styles;
