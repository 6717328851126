/* eslint-disable */
import css from 'styled-jsx/css';
import COLORS from '@utils/colors';

const styles = css`
  .footer-copyright {
    margin-top: 0.5rem;

    small {
      color: ${COLORS.black900};
      font-size: 12px;
      line-height: 18px;
    }
  }
`;

export default styles;
