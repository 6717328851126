/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';
import { BREAKPOINT } from '@utils/constants';

const { mobile } = BREAKPOINT;

const styles = css`
  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: ${COLORS.black200};
    padding: 0 24px;
    margin-top: auto;
  }

  .footer-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 1280px;
    margin: auto;
  }
  .footer-top-block {
    display: flex;
    width: 100%;
    @media screen and (max-width: ${mobile}px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
  .footer-bottom-block {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 16px 0 24px;
    @media screen and (max-width: ${mobile}px) {
      flex-direction: column;
      margin-bottom: 20px;
      & > :global(div) {
        margin-bottom: 16px;
      }
    }
  }
  .footer-logo-wrapper {
    flex: 1;
    margin-top: 40px;
    @media screen and (max-width: ${mobile}px) {
      margin-bottom: 24px;
    }
  }
  .footer-divider {
    margin: 0 auto;
    width: 100%;
    height: 1px;
    background: ${COLORS.black300};
  }
`;

export default styles;
