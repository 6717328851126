/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';

const styles = css`
  :global(.icon-arrow) {
    transition: transform 0.5s ease;
  }
  :global(.icon-arrow.color-gray) {
    :global(path) {
      fill: ${COLORS.black400};
    }
  }
  :global(.icon-arrow.color-blue) {
    :global(path) {
      fill: ${COLORS.primary};
    }
  }
  :global(.icon-arrow.color-red600) {
    :global(path) {
      fill: ${COLORS.red600};
    }
  }
  :global(.icon-arrow.left) {
    transform: rotate(180deg);
  }
  :global(.icon-arrow.up) {
    transform: rotate(270deg);
  }
  :global(.icon-arrow.down) {
    transform: rotate(90deg);
  }
  :global(.icon-arrow.right) {
    transform: rotate(0deg);
  }
`;

export default styles;
