/* eslint-disable */
import useTranslation from 'next-translate/useTranslation';
import React, { useMemo } from 'react';

import Link from '@components/Link';

import { useAppSelector } from '@hooks/useAppSelector';
import routes from '@utils/routes';

import styles from './index.css';
import useLocation from '@hooks/useLocation';

const FooterBottomLinks = () => {
  const { t } = useTranslation('footer');
  const { isJapan } = useLocation();
  const { location } = useAppSelector((state) => ({
    location: state.location,
  }));

  const TERMS_OF_TOUR = {
    glb: routes.termsOfService,
    kr: `${process.env.NEXT_PUBLIC_GCS_URL}/footer/terms_of_travel_ko.pdf`,
    jp: `${process.env.NEXT_PUBLIC_GCS_URL}/footer/%E6%A8%99%E6%BA%96%E6%97%85%E8%A1%8C%E6%A5%AD%E7%B4%84%E6%AC%BE_20221228.pdf`,
  };
  const links = useMemo(
    () => [
      {
        href: routes.privacyPolicy,
        label: t('privacyPolicy'),
      },
      {
        href: routes.termsOfService,
        label: t('termsOfService'),
      },
      {
        href: routes.commercialTransactions,
        label: t('commercialTransactions'),
      },
      {
        href: TERMS_OF_TOUR[location.country?.code],
        label: t('termsOfTour'),
      },
      isJapan
        ? {
            href: `${process.env.NEXT_PUBLIC_GCS_URL}/footer/%E5%8F%96%E5%BC%95%E6%9D%A1%E4%BB%B6%E8%AA%AC%E6%98%8E%E6%9B%B8%E7%AD%89%EF%BC%88%E6%89%8B%E9%85%8D%E6%97%85%E8%A1%8C%E5%A5%91%E7%B4%84%E6%9D%A1%E4%BB%B6%E6%9B%B8%E3%83%BB%E4%BC%81%E7%94%BB%E6%97%85%E8%A1%8C%E5%A5%91%E7%B4%84%E6%9D%A1%E4%BB%B6%E6%9B%B8%EF%BC%89_20231116.pdf`,
            label: t('conditionsOfTour'),
          }
        : null,
      {
        href: 'https://www.wantedly.com/companies/kabuk/projects',
        label: t('recruit'),
      },
      {
        href: routes.companyProfile,
        label: t('company'),
      },
    ],
    [location, t]
  );
  return (
    <div className="footer-bottom-links-wrapper">
      <ul className="footer-bottom-links">
        {links.map((link) => {
          if (!link?.href) {
            return;
          }
          return (
            <li key={link.label} className="footer-bottom-link">
              {link.href.startsWith('http') ? (
                <a href={link.href} target="_blank" rel="noopener noreferrer">
                  {link.label}
                </a>
              ) : (
                <Link href={link.href}>{link.label}</Link>
              )}
            </li>
          );
        })}
      </ul>
      <style jsx>{styles}</style>
    </div>
  );
};

export default FooterBottomLinks;
