import IconArrowSvg from '@assets/icon-arrow-current.svg';
import classNames from 'classnames';
import styles from './css';

type IconProps = {
  color?: 'black' | 'gray' | 'blue' | 'red600';
  direction?: 'right' | 'left' | 'up' | 'down';
};

const IconArrow = ({
  color = 'black',
  direction = 'right',
}: IconProps = {}) => (
  <>
    <IconArrowSvg
      className={classNames(
        { [`color-${color}`]: color },
        'icon-arrow',
        direction
      )}
    />
    <style jsx={true}>{styles}</style>
  </>
);

export default IconArrow;
