import { useMemo } from 'react';
import { useAppSelector } from '@hooks/useAppSelector';
import {
  ALL_LANGUAGES,
  COUNTRY_CODES,
  SUPPORT_LANGUAGES,
} from '@utils/constants';
import { useRouter } from 'next/router';

interface Options {
  includeCurrentLanguage?: boolean;
}

export const useSupportLanguages = ({
  includeCurrentLanguage = false,
}: Options) => {
  const { location, neighbor } = useAppSelector((state) => ({
    location: state.location,
    neighbor: state.neighbor,
  }));
  const { locale } = useRouter();

  const serviceCountryCode =
    neighbor?.registration_country?.code ??
    location?.country.code ??
    COUNTRY_CODES.global;

  const supportLanguages = SUPPORT_LANGUAGES[serviceCountryCode];

  const currentLanguage = useMemo(
    () => ALL_LANGUAGES.find((language) => language.key === locale) ?? null,
    [locale]
  );

  const isCurrentLanguageSupported = useMemo(
    () => supportLanguages.some((language) => language.key === locale),
    [locale, supportLanguages]
  );

  return includeCurrentLanguage &&
    !isCurrentLanguageSupported &&
    currentLanguage
    ? [...supportLanguages, ...(currentLanguage ? [currentLanguage] : [])]
    : supportLanguages;
};
