import COLORS from '@utils/colors';
import css from 'styled-jsx/css';

const styles = css`
  .footer-lang {
    display: flex;
    .footer-lang-select {
      display: flex;
      position: relative;
      align-items: center;
      min-width: 160px;
      color: ${COLORS.black900};
      font-size: 16px;
      font-weight: bold;
      background: none;
      border: 1px solid ${COLORS.black400};
      border-radius: 40px;
      cursor: pointer;
      &::before {
        content: url('/images/icon/globe.svg');
        position: absolute;
        left: 20px;
        display: block;
        width: 20px;
        height: 20px;
        pointer-events: none;
      }
      &:hover {
        background: #ebebeb;
      }
      select {
        display: block;
        padding: 7px 28px 7px 52px;
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .footer-lang-wrap {
      position: relative;
    }
    .footer-lang-arrow {
      right: 8px;
      position: absolute;
      top: 0;
      bottom: 0;
      display: flex;
      align-items: center;
      pointer-events: none;
    }
  }
`;

export default styles;
