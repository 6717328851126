/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';

const styles = css`
  .collapse-menu {
    padding: 1.25rem 0;
    border-bottom: 1px solid ${COLORS.black10};
    border-top: 1px solid ${COLORS.black10};
    & + .collapse-menu {
      border-top: none;
    }
    &.no-border {
      border: none;
    }
    &.bold {
      font-weight: bold;
    }
    .collapse-heading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      cursor: pointer;
      .collapse-title {
        font-size: 1rem;
        color: ${COLORS.black2};
        text-align: left;
      }
      :global(.icon-plus) {
        cursor: pointer;
        width: 16px;
        min-width: 16px;
        margin-left: 8px;
      }
    }
    .collapse-content {
      white-space: pre-wrap;
      overflow: hidden;
      transition: all 0.5s ease;
      max-height: 0;
      &.is-open {
        margin-top: 1rem;
      }
    }
  }
`;

export default styles;
