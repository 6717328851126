import css from 'styled-jsx/css';

const styles = css`
  .footer-logo {
    cursor: pointer;
    width: 100%;
  }
`;

export default styles;
