import React from 'react';
import IconPlusSvg from '@assets/icon-plus.svg';
import { SVG_SIZES } from '@utils/constants';
import styles from './css';

type IconProps = {
  className?: string;
  color?: 'white' | 'black400' | 'black600' | 'black900' | 'primary';
  size?: 'xs' | 'md';
};

const IconPlus = ({
  className = '',
  color = 'black900',
  size = 'xs',
}: IconProps = {}) => {
  const style = {
    height: SVG_SIZES[size],
    width: SVG_SIZES[size],
  };

  return (
    <>
      <IconPlusSvg
        className={`icon-plus ${className} color-${color}`}
        style={style}
      />
      <style jsx={true}>{styles}</style>
    </>
  );
};

export default IconPlus;
