/* eslint-disable */
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import React, { useCallback } from 'react';

import Link from '@components/Link';
import { Mobile, Desktop } from '@components/Layout';

import Collapse from '@atoms/Collapse';

import routes, { route } from '@utils/routes';

import styles from './index.css';
import useLocation from '@hooks/useLocation';

const categories = [
  {
    titleKey: 'aboutHafh',
    items: [
      {
        href: routes.about,
        labelKey: 'serviceSummary',
      },
      {
        href: routes.hafhPlan,
        labelKey: 'aboutPlan',
      },
      {
        href: routes.coin,
        labelKey: 'aboutCoin',
      },
    ],
  },
  {
    titleKey: 'help',
    items: [
      {
        href: routes.faq,
        labelKey: 'faq',
      },
      {
        href: routes.contact,
        labelKey: 'contact',
      },
      {
        labelKey: 'facilityParticipation',
        href: 'https://form.run/@hafh-contact-accommodation',
      },
    ],
  },
];
const FooterMenu = () => {
  const { t } = useTranslation('footer');
  const { locale } = useRouter();
  const { isJapan } = useLocation();

  const renderItems = useCallback(
    (menu) => (
      <ul>
        <li className="footer-menu-category-title">{t(menu.titleKey)}</li>
        {menu.items.map((item, j) => {
          return (
            <li key={j} className="footer-menu-item">
              {<Link href={item.href}>{t(item.labelKey)}</Link>}
            </li>
          );
        })}
        <style jsx>{styles}</style>
      </ul>
    ),
    [isJapan, t]
  );

  return (
    <div className="footer-menu-container">
      {categories.map((menu, i) => {
        if (menu.exceptionLocales && menu.exceptionLocales.includes(locale)) {
          return null;
        }
        if (menu.items) {
          return (
            <div key={i} className="footer-menu-category">
              <Mobile>
                <Collapse
                  title={t(menu.titleKey)}
                  iconType="arrow"
                  noBorder
                  bold
                >
                  {renderItems(menu)}
                </Collapse>
              </Mobile>
              <Desktop>{renderItems(menu)}</Desktop>
            </div>
          );
        }
        return (
          <div key={i} className="footer-menu-category-external-link">
            <Link href={menu.href} target="_blank">
              <>
                {t(menu.titleKey)}
                {menu.icon}
              </>
            </Link>
          </div>
        );
      })}
      <style jsx>{styles}</style>
    </div>
  );
};

export default FooterMenu;
