/* eslint-disable */
import css from 'styled-jsx/css';

import COLORS from '@utils/colors';

const styles = css`
  :global(.icon-plus) {
    transition: transform 0.5s ease;
  }
  :global(.icon-plus.color-white) {
    :global(g) {
      fill: ${COLORS.white};
    }
  }
  :global(.icon-plus.color-black400) {
    :global(g) {
      fill: ${COLORS.black400};
    }
  }
  :global(.icon-plus.color-black900) {
    :global(g) {
      fill: ${COLORS.black900};
    }
  }
  :global(.icon-plus.color-primary) {
    :global(g) {
      fill: ${COLORS.primary};
    }
  }
  :global(.icon-plus.close) {
    transform: rotate(-45deg);
  }
`;

export default styles;
